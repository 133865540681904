<template>
    <div v-if="newComp && newComp.cert_stat!=2" class="tip-block block">
        <div v-if="newComp.cert_stat==0" class="flex">
            <div class="font14-grey" style="line-height: 20px;">
                <i class="iconfont icon-weirenzheng-" style="font-size:18px;color: #4D4D4D;position: relative;top: 2px;"></i>
                <span class="font14-grey1">您的企业信息尚未认证，请点击 &nbsp;</span>
                <span @click="goApprove()" style="color:#016FFF;cursor:pointer;margin-right:10px;">去认证</span>
            </div>
            <div><span style="font-size: 12px;line-height: 14px;" class="font14-grey1">(认证成功后解锁以下功能)</span></div>
        </div>
        <div v-if="newComp.cert_stat==1" class="flex">
            <div class="font14-grey" style="line-height: 20px;">
                <i class="iconfont icon-shenhedengdai" style="font-size:18px;color: #4D4D4D;position: relative;top: 2px;"></i>
                <span style="margin-right:10px;">您的企业信息正在认证中，请点击</span>
                <span @click="approveLink()" style="color:#016FFF;cursor: pointer;margin-right:10px;">查看审核进度</span>
            </div>
            <div><span style="font-size: 12px;line-height: 14px;" class="font14-grey1">(认证成功后解锁以下功能)</span></div>
        </div>
        <!-- <div v-if="newComp.cert_stat==2">
            <span class="icon iconfont icon-dggfg" style="color: #CC995A;"></span>验证通过
        </div> -->
        <div v-if="newComp.cert_stat==3" class="flex">
            <div class="font14-grey" style="line-height: 20px;">
                <i class="iconfont icon-bianzu1" style="font-size:18px;color: #4D4D4D;position: relative;top: 2px;"></i>
                <span style="margin-right:10px;">您的企业信息认证不通过，请点击 &nbsp;</span>
                <span @click="approveLink()" style="color:#016FFF;margin-right:10px;cursor: pointer;">重新认证</span>
            </div>
            <div><span style="font-size: 12px;line-height: 14px;" class="font14-grey1">(认证成功后解锁以下功能)</span></div>
        </div>

        <el-dialog width="500px" @close="close" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <div v-if="popTitle == '企业认证'">
                <Sign :popTitle="popTitle" @data="refreshItems" @hide="hide" @signState="signState"></Sign>
            </div>
            <div v-if="popTitle == '遇到问题'">
                <SignBack :popTitle="popTitle" @hide="popVisible=false"></SignBack>
            </div>
        </el-dialog>
    </div>

</template>
<script>
    import { goCompanyVerify,getCompInfoById } from '@/service/company';
    import Sign from '@/components/sign/sign.vue';
    import SignBack from '@/components/sign/signBack.vue';

    export default {
        props: ['comp'],
        components: {
            Sign,SignBack
        },
        data() {
            return {
                popVisible: false,
                popTitle: null,
                legal:null,
                newComp:null,
            }
        },
        async created() {
            this.newComp = this.comp;
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                if (this.$store.state.loginUser.user.comp_creator==1) {
                    getCompInfoById(this.$store.state.loginUser.comp.id).then(rst => {
                        if (rst) {
                            this.newComp = rst;
                            // this.newComp.fdd_verify_url = rst.fdd_verify_url;
                        }
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                }
            },
            signState(text) {
                this.popTitle = text;
                this.popVisible = true;
            },
            hide() {
                this.popVisible = false;
                this.$message.success('状态已更新');
            },
            goApprove() {
                console.log(this.newComp);
                this.legal = {
                    comp_id:this.newComp.id,
                }
                goCompanyVerify(this.legal).then(rst => {
                    if (rst.verify_url) {
                        window.open(rst.verify_url);
                        this.signState("企业认证");
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            close() {
                this.refreshItems();
            },
            approveLink() {
                if (this.newComp.fdd_verify_url) {
                    window.open(this.newComp.fdd_verify_url);
                    this.signState("企业认证");
                }
            },
        },
    };
</script>
<style scoped src="../comp/style.css"></style>
<style scoped>
    .tip-block{
        padding: 20px 30px;
    }
    .tip-block .iconfont{
        margin-right: 10px;
    }
</style>