<template>
    <div v-if="comp" class="center-frm">
        <CertState :comp="comp"></CertState>
        <template v-if="comp.comp_type==1">
            <div>
                <div class="title">设计作品</div>
                <div class="content">
                    <!-- :disabled="comp.cert_stat!=2" -->
                    <router-link to="/factory/market" class="icon-block">
                    <!-- :class="{'active':comp.cert_stat!=2}" -->
                        <span class="icon iconfont icon-chaoshi"></span>
                        <!-- <svg class="icon iconfont" aria-hidden="true">
                            <use xlink:href="#icon-zu1472"></use>
                        </svg> -->
                        <div class="title">设计超市</div>
                    </router-link>
                </div>
            </div>
            <div>
                <div class="title">商品管理</div> 
                <div class="content">
                    <router-link to="/factory/designBq" class="icon-block">
                        <span class="icon iconfont icon-shangpin-1"></span>
                        <div class="title">版权购买</div>
                    </router-link>
                    <router-link to="/factory/hzzp" class="icon-block">
                        <span class="icon iconfont icon-biaodan"></span>
                        <div class="title">合作分成</div>
                    </router-link>
                    <router-link to="/factory/zysp" class="icon-block">
                        <span class="icon iconfont icon-zf_shejijaingshenp1"></span>
                        <div class="title">自有商品</div>
                    </router-link>
                    <router-link to="/factory/txgl" class="icon-block">
                        <span class="icon iconfont icon-yixuantaoxi"></span>
                        <div class="title">商品分类</div>
                    </router-link>
                </div>
            </div>
            <div>
                <div class="title">订单管理</div>
                <div class="content">
                    <router-link to="/factory/xsdd" class="icon-block">
                        <span class="icon iconfont icon-chaoshi"></span>
                        <div class="title">销售订单</div>
                    </router-link>
                </div>
            </div>
            <!-- <div>
                <div class="title">销售手册</div>
                <div class="content">
                    <router-link to="/factory/xssc" class="icon-block">
                        <span class="icon iconfont icon-chaoshi"></span>
                        <div class="title">产品画册</div>
                    </router-link>
                </div>
            </div> -->
            <!-- <div>
                <div class="title">生意管理</div>
                <div class="content">
                    <router-link to="/factory/joinWant" class="icon-block">
                        <span class="icon iconfont icon-xiaoxi"></span>
                        <div class="title">待应答生意</div>
                    </router-link>
                </div>
            </div> -->
            <div>
                <div class="title">政府政策申请</div>
                <div class="content">
                    <router-link to="/factory/gov/cxghyssyssq" class="icon-block">
                        <span class="icon iconfont icon-zu8"></span>
                        <div class="title">产线规划送审与验收申请</div>
                    </router-link>
                    <router-link to="/factory/gov/jrcpsq" class="icon-block">
                        <span class="icon iconfont icon-jiajudai"></span>
                        <div class="title">金融产品申请</div>
                    </router-link>
                    <router-link to="/factory/gov/zjjmsq" class="icon-block">
                        <span class="icon iconfont icon-jine1"></span>
                        <div class="title">租金减免申请</div>
                    </router-link>
                    <router-link to="/factory/gov/qytgsq" class="icon-block">
                        <span class="icon iconfont icon-bianzu13"></span>
                        <div class="title">企业退规申请</div>
                    </router-link>
                    <router-link to="/factory/gov/qyrg" class="icon-block">
                        <span class="icon iconfont icon-qianyue-sjcs"></span>
                        <div class="title">企业入规申请</div>
                    </router-link>
                    <router-link to="/factory/gov/designAllowance" class="icon-block">
                        <span class="icon iconfont icon-qianyue-sjcs"></span>
                        <div class="title">设计奖补申请</div>
                    </router-link>
                </div>
            </div>
            <!-- mes生产管理 -->
            <!-- <div v-if="MesMenus">
                <div class="title">mes生产管理</div>
                <div v-for="(menu,idx) in MesMenus" :key="idx">
                    <div class="sub-title">{{menu.name}}</div>
                    <div class="content">
                        <router-link v-for="(func,_idx) in menu.funcs" :key="_idx" :to="func.path" class="icon-block">
                            <span :class="[func.iconClass]"></span>
                            <div class="title">{{func.title}}</div>
                        </router-link>
                    </div>
                </div>
            </div> -->
            <div>
<!--                <div class="title">生产管理</div>-->
<!--                <div class="content">-->
<!--                    <div><img src="@/assets/images/fw/Group 9.png"></div>-->
<!--                    <div>参加生产管理咨询，为获得订单做好准备</div>-->
<!--                    &lt;!&ndash; <div>-->
<!--                        <el-button type="warning">了解详情</el-button>-->
<!--                    </div> &ndash;&gt;-->
<!--                </div>-->
                <!-- <MesCenter></MesCenter> -->
            </div>
        </template>
        <template v-if="comp.comp_type==2">
            <div>
                <div class="title">设计管理</div>
                <div class="content">
                    <router-link to="/desgin/stored" class="icon-block">
                        <span class="icon iconfont icon-OvalPathmengban"></span>
                        <div class="title">设计作品库</div>
                    </router-link>
                    <router-link to="/desgin/fchz" class="icon-block">
                        <span class="icon iconfont icon-eee2"></span>
                        <div class="title">分成合作</div>
                    </router-link>
                </div>
            </div>
            <div>
                <div class="title">订单管理</div>
                <div class="content">
                    <router-link to="/desgin/order/designBq" class="icon-block">
                        <span class="icon iconfont icon-OvalPathmengban"></span>
                        <div class="title">设计版权</div>
                    </router-link>
                    <router-link to="/desgin/order/demandCustomize" class="icon-block">
                        <span class="icon iconfont icon-OvalPathmengban"></span>
                        <div class="title">需求定制</div>
                    </router-link>
                </div>
            </div>
            <!-- <div>
                <div class="title">合同管理</div>
                <div class="content">
                    <router-link to="/desgin/htmb" class="icon-block">
                        <span class="icon iconfont icon-qianyue-sjcs"></span>
                        <div class="title">销售合同模板</div>
                    </router-link>
                </div>
            </div> -->
        </template>
        <template v-if="comp.comp_type==3">
            <div>
                <div class="title">采购管理</div>
                <div class="content">
                    <router-link to="/sale/pur/collect" class="icon-block">
                        <span class="icon iconfont icon-wodeshoucang"></span>
                        <div class="title">我的收藏</div>
                    </router-link>
                    <router-link to="/sale/pur/cgc" class="icon-block">
                        <span class="icon iconfont icon-chaoshi"></span>
                        <div class="title">采购车</div>
                    </router-link>
                    <router-link to="/sale/pur/cgdd" class="icon-block">
                        <span class="icon iconfont icon-caigou1"></span>
                        <div class="title">采购订单</div>
                    </router-link>
                    <router-link to="/factory/demandOrder" class="icon-block">
                        <span class="icon iconfont icon-caigou1"></span>
                        <div class="title">需求订单</div>
                    </router-link>
                </div>
            </div>
            <!-- <div>
                <div class="title">店铺渠道</div>
                <div class="content">
                    <router-link to="/sale/shop/list" class="icon-block">
                        <span class="icon iconfont icon-dianpuguanli1"></span>
                        <div class="title">店铺管理</div>
                    </router-link>
                    <router-link to="/sale/shop/putaway/list" class="icon-block">
                        <span class="icon iconfont icon-r"></span>
                        <div class="title">上架总览</div>
                    </router-link>
                </div>
            </div> -->
        </template>
        <template v-if="comp.comp_type">
            <div v-if="[1,3].indexOf(comp.comp_type)>=0">
                <div class="title">需求管理</div>
                <div class="content">
                    <router-link to="/want/wantIssue" class="icon-block">
                        <span class="icon iconfont icon-tubiao_fabushangpin"></span>
                        <div class="title">需求发布</div>
                    </router-link>
                    <router-link to="/want/wantFollow" class="icon-block">
                        <span class="icon iconfont icon-genjin"></span>
                        <div class="title">需求跟进</div>
                    </router-link>
                </div>
            </div>
            <div v-if="[1,2,4].indexOf(comp.comp_type)>=0">
                <div class="title">生意管理</div>
                <div class="content">
                    <router-link to="/want/joinWant" class="icon-block">
                        <span class="icon iconfont icon-xiaoxi"></span>
                        <div class="title">应答生意</div>
                    </router-link>
                    <router-link v-if="comp.comp_type==4" 
                        to="/want/sellerOrder" class="icon-block">
                        <span class="icon iconfont icon-qianyue-sjcs"></span>
                        <div class="title">需求订单</div>
                    </router-link>
                </div>
            </div>
        </template>
        <template v-if="[1,2,3,4].includes(comp.comp_type)">
            <div>
                <div class="title">合同管理</div>
                <div class="content">
                    <router-link to="/contract/contract" class="icon-block">
                        <span class="icon iconfont icon-zu41"></span>
                        <div class="title">经办合同</div>
                    </router-link>
                    <router-link to="/contract/stamp" class="icon-block">
                        <span class="icon iconfont icon-lujing19"></span>
                        <div class="title">印章管理</div>
                    </router-link>
                </div>
            </div>
        </template>
        <!-- <template v-if="[1,3].indexOf(comp.comp_type)>=0">
            <div>
                <div class="title">我的</div>
                <div class="content">
                    <router-link v-if="comp.comp_type==1" to="/mine/factory/white" class="icon-block">
                        <span class="icon iconfont icon-qudao"></span>
                        <div class="title">渠道分组</div>
                    </router-link>
                    <router-link v-if="comp.comp_type==3" to="/mine/sale/white" class="icon-block">
                        <span class="icon iconfont icon-qudao"></span>
                        <div class="title">渠道白名单</div>
                    </router-link>
                </div>
            </div>
        </template> -->
        <template v-if="comp.comp_type==99">
            <div>
                <div class="title">政策管理</div>
                <div class="content">
                    <router-link to="/center" class="icon-block">
                        <span class="icon iconfont icon-zf_shejijaingshenp"></span>
                        <div class="title">设计费申请</div>
                    </router-link>
                    <router-link to="/center" class="icon-block">
                        <span class="icon iconfont icon-zf_Group"></span>
                        <div class="title">家具贷</div>
                    </router-link>
                    <router-link to="/center" class="icon-block">
                        <span class="icon iconfont icon-zf_jitifang"></span>
                        <div class="title">集体商标防伪推广</div>
                    </router-link>
                    <router-link to="/center" class="icon-block">
                        <span class="icon iconfont icon-zf_shejibanqyanbaofu"></span>
                        <div class="title">设计版权保护中心</div>
                    </router-link>
                    <router-link to="/center" class="icon-block">
                        <span class="icon iconfont icon-zf_shejizizhi"></span>
                        <div class="title">企业资质等级管理</div>
                    </router-link>
                </div>
            </div>
            <div>
                <div class="title">集成超市</div>
                <div class="content">
                    <router-link to="/center" class="icon-block">
                        <span class="icon iconfont icon-caiyuandai"></span>
                        <div class="title">财源贷</div>
                    </router-link>
                    <router-link to="/center" class="icon-block">
                        <span class="icon iconfont icon-zf_yun"></span>
                        <div class="title">上云MES推广</div>
                    </router-link>
                </div>
            </div>
            <div>
                <div class="title">数据中心</div>
                <div class="content">
                    <router-link to="/center" class="icon-block">
                        <span class="icon iconfont icon-tihuan"></span>
                        <div class="title">防伪数据中心</div>
                    </router-link>
                    <router-link to="/center" class="icon-block">
                        <span class="icon iconfont icon-zf_banquanshuju"></span>
                        <div class="title">版权数据中心</div>
                    </router-link>
                </div>
            </div>
        </template>
        <template v-if="[9901,9902,9903].includes(comp.comp_type)">
            <div>
                <div class="title">审批管理</div>
                <div class="content">
                    <router-link to="/approve/allowance" class="icon-block">
                        <span class="icon iconfont icon-zf_shejijaingshenp"></span>
                        <div class="title">设计奖补申请</div>
                    </router-link>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import CertState from '@/views/center/ItemCertState';
    export default {
        components: {
            CertState
        },
        data() {
            return {
                comp: this.$store.state.loginUser.comp,
            }
        },
        created() {
            
        },
        methods: {

        }
    }
</script>
<style scoped>
    .center-frm {
        color: rgba(41, 41, 41, 1);
        height: 100%;
        overflow: auto;
    }

    .center-frm>div {
        width: 75%;
        max-width: 1200px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px 0px rgba(192, 179, 179, 0.5);
        border-radius: 10px 10px 10px 0px;
        margin: 20px auto 20px;
    }

    .center-frm>div>.title {
        height: 75px;
        line-height: 75px;
        font-weight: 600;
        color: rgba(59, 59, 59, 1);
        font-size: 18px;
        padding-left: 30px;
        border-bottom: 2px solid rgba(230, 230, 230, 1);
    }

    .content {
        padding: 40px;
        text-align: center;
        overflow: hidden;
    }
    .content span.iconfont.active {
        color: #ccc;
    }
    .sub-title{
        padding: 20px 30px;
        font-weight: bold;
        border-bottom: 1px solid #eee;
    }
    .icon-block {
        float: left;
        padding: 30px;
        cursor: pointer;
    }

    .icon-block .icon {
        font-size: 38px;
    }

    .icon-block .title {
        font-weight: 500;
        color: rgba(128, 128, 128, 1);
        font-size: 16px;
    }
</style>